body {
	background-color: #2a2a2a;
	color:  #f0f0f0;
}

a:link {
	color:  #00d9ff;
}
a:visited {
	color: #00d9ff;
}
a:hover {
	color: #ff00e9;
}
a:active {
	color: #0dff00;
}

h1 > a:link {
	color:  #cecece;
}
h1 > a:visited {
	color: #cecece;
}
h1 > a:hover {
	color: #ff00e9;
}
h1 > a:active {
	color: #0dff00;
}

.App {
	font-family: "Helvetica Neue", -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,sans-serif;
	font-weight: 300;
	max-width: 1200px;
	margin: 0 auto;
	display: block;
	padding: 0 1em;
}
img {
	width: 100%;
}

body {
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	/* background-image: url('black_squares.svg'); */
}

hr {
	margin-bottom: 2em;
}

section.project {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 2em;
	background-color: rgb(255 255 255 / 10%);
}
.imgBackground{
	height: 100%;
	display: flex;
	align-items: center;
	/* background-color: rgb(255 255 255 / 70%); */
}

p.smaller {
	font-size: smaller;
}

@media screen and (max-width: 800px){
	.grid {grid-template-columns: 1fr 1fr !important}
}

@media screen and (max-width: 500px){
	.grid {grid-template-columns: 1fr !important}
}

/* @media screen and (max-width: 460px){
	.grid {grid-template-columns: 1fr !important}
} */